<template>
  <div class="h-full w-full" id="sounds">
    <div class="border-b flex border-primary-300 w-full items-center">
      <div
        v-for="key in Object.keys(tabmap)"
        :key="key"
        class="border-transparent cursor-pointer flex border-b-4 h-11 text-sm mr-9 w-full items-center justify-center last:mr-0"
        :class="{'font-bold border-red': selectedSoundOption === key}"
        @click="toggle(key)"
      >
        {{tabmap[key]}}
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <YourMedia v-if="selectedSoundOption === 'yourMedia'"/>
      <MusicLibrary v-if="selectedSoundOption === 'musicLibrary'"/>
      <SyntheticVoices v-if="selectedSoundOption === 'syntheticVoices'"/>
    </transition>
  </div>
</template>

<script>
import store from '@/services/store'

export default {
  name: 'Sounds',
  components: {
    MusicLibrary: () => import(/* webpackChunkName: "MusicLibrary" */'./MusicLibrary/MusicLibrary'),
    YourMedia: () => import(/* webpackChunkName: "YourMedia" */'./Media/Media'),
    SyntheticVoices: () => import(/* webpackChunkName: "SyntheticVoices" */'./SyntheticVoices/SyntheticVoices')
  },
  data () {
    return {
      tabmap: {
        yourMedia: 'Your Media',
        musicLibrary: 'Music Library',
        syntheticVoices: 'Synthetic Voices'
      },
      selectedSoundOption: ''
    }
  },
  created () {
    this.selectedSoundOption = 'musicLibrary'
  },
  methods: {
    toggle (tab) {
      this.selectedSoundOption = tab
      store.commit('modal/clearSelectedFile')
    }
  }
}
</script>

<style lang="scss">
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.2s ease-in-out;
  opacity: 1;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

// Free sounds credits
.free-sounds-credits {
  display: flex;
  flex-direction: column;
  padding: 24px;
  // height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  margin: 0;
  border-radius: 9px;

  .back-btn {
    width: max-content;
    background: #FFFFFF;
    padding: 4px 12px;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
    font-weight: bold;
    font-size: 16px;
    margin-left: 8px;
    color: #4F4F4F;
    cursor: pointer;

    &:hover {
      background: #F6F6F6;
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    margin: 40px 8px 0 8px;

    .image-container {
      flex: 1 0 0;
    }

    .credits {
      flex: 2 0 0;
      text-align: left;

      .credit {
        display: flex;
        margin-bottom: 28px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #4F4F4F;

        .icon {
          margin: 4px 12px 4px 0;
        }

        a {
          cursor: pointer;
          color: #4F4F4F;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
